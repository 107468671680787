import React, { useEffect } from 'react';
import Modal from 'react-modal';
import styles from './NoResultsModal.module.scss';

export const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 3px 30px rgba(0, 0, 0, 0.08)',
    borderRadius: '15px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 56px'
  }
};

const NoResultsModalComponent = ({ isOpen, onRequestClose, onConfirm }) => {
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      shouldCloseOnOverlayClick={false}>
      <h1 className={styles.processModalTitle}>No results</h1>

      <span className={styles.processModalDescriptionText}>There are no results.</span>
      <span className={styles.processModalAdditionalText}>Your credits will not be used.</span>
      <div className={styles.modalButtonContainer}>
        <button onClick={onRequestClose} className={styles.closeProcessModalButton}>
          Cancel
        </button>
        <button onClick={onConfirm} className={styles.confirmProcessModalButton}>
          Recalculate
        </button>
      </div>
    </Modal>
  );
};

export default NoResultsModalComponent;
