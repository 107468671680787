/* eslint-disable no-case-declarations */
import {
  CHANGE_REPORT_TYPE,
  ADD_NEW_ROW,
  DELETE_ROW,
  DUPLICATE_ROW,
  UPDATE_MULTI_ADDRESS,
  UPDATE_INPUT_FIELD,
  UPDATE_CALCULATE_RESULT,
  RESET_REPORT,
  UPDATE_REPORT_ID,
  CLEAR_REPORT
} from '../actions';

import { v1 as uuid } from 'uuid';

const defaultSingleId = uuid();
const defaultMultiId = uuid();

const defaultSingleReportRow = {
  address: '',
  bedrooms: '',
  bathrooms: '',
  currentRent: ''
};
const defaultMultiReportRow = {
  address: '',
  description: '',
  bedrooms: '',
  bathrooms: '',
  count: ''
};

const defaultSingleReport = {
  id: defaultSingleId,
  reportType: 'single',
  data: [defaultSingleReportRow]
};
const defaultMultiReport = {
  id: defaultMultiId,
  reportType: 'multi',
  address: '',
  data: [defaultMultiReportRow]
};

const initialState = {
  activeReportType: 'single',
  activeSingleReport: defaultSingleReport,
  activeMultiReport: defaultMultiReport,
  reports: []
};

export const gprCalculatorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const activeReportType = payload?.activeReportType;

  switch (type) {
    case CHANGE_REPORT_TYPE:
      return {
        ...state,
        activeReportType: payload
      };
    case ADD_NEW_ROW:
      const reportType = activeReportType === 'single' ? 'activeSingleReport' : 'activeMultiReport';
      const updatedReport = {
        ...state[reportType],
        data: [
          ...state[reportType].data,
          reportType === 'activeSingleReport' ? defaultSingleReportRow : defaultMultiReportRow
        ]
      };
      return {
        ...state,
        [reportType]: updatedReport
      };
    case DELETE_ROW:
      if (activeReportType === 'single') {
        const newReportData = state.activeSingleReport.data.filter((item, i) => payload.id !== i);
        return {
          ...state,
          activeSingleReport: { ...state.activeSingleReport, data: newReportData }
        };
      } else {
        const newReportData = state.activeMultiReport.data.filter((item, i) => payload.id !== i);
        return {
          ...state,
          activeMultiReport: { ...state.activeMultiReport, data: newReportData }
        };
      }
    case RESET_REPORT:
      if (activeReportType === 'single') {
        return {
          ...state,
          activeSingleReport: {
            id: payload.id,
            reportRype: 'single',
            data: [defaultSingleReportRow]
          }
        };
      } else {
        return {
          ...state,
          activeMultiReport: {
            id: payload.id,
            reportRype: 'multi',
            address: '',
            data: [defaultMultiReportRow]
          }
        };
      }
    case UPDATE_REPORT_ID:
      if (activeReportType === 'single') {
        return {
          ...state,
          activeSingleReport: {
            ...state.activeSingleReport,
            id: payload.newId
          }
        };
      } else {
        return {
          ...state,
          activeMultiReport: {
            ...state.activeMultiReport,
            id: payload.newId
          }
        };
      }
    case DUPLICATE_ROW:
      if (activeReportType === 'single') {
        const duplicatedRow = state.activeSingleReport.data.filter((item, i) => payload.id === i);
        return {
          ...state,
          activeSingleReport: {
            ...state.activeSingleReport,
            data: [...state.activeSingleReport.data, ...duplicatedRow]
          }
        };
      } else {
        const duplicatedRow = state.activeMultiReport.data.filter((item, i) => payload.id === i);
        return {
          ...state,
          activeMultiReport: {
            ...state.activeMultiReport,
            data: [...state.activeMultiReport.data, ...duplicatedRow]
          }
        };
      }
    case UPDATE_INPUT_FIELD:
      const { id, type, value } = payload;
      if (activeReportType === 'single') {
        const updatedData = state.activeSingleReport.data.map((item, i) =>
          i === id ? { ...item, [type]: value } : item
        );
        return {
          ...state,
          activeSingleReport: { ...state.activeSingleReport, data: updatedData }
        };
      } else {
        const updatedData = state.activeMultiReport.data.map((item, i) =>
          i === id ? { ...item, [type]: value } : item
        );
        return {
          ...state,
          activeMultiReport: { ...state.activeMultiReport, data: updatedData }
        };
      }
    case UPDATE_MULTI_ADDRESS:
      return {
        ...state,
        activeMultiReport: { ...state.activeMultiReport, address: payload }
      };
    case UPDATE_CALCULATE_RESULT:
      return {
        ...state,
        reports: [...state.reports, ...payload]
      };
    case CLEAR_REPORT:
      return {
        ...state,
        activeSingleReport: {
          id: state.activeSingleReport.id,
          reportType: 'single',
          data: [defaultSingleReportRow]
        },
        activeMultiReport: {
          id: state.activeMultiReport.id,
          reportType: 'multi',
          address: '',
          data: [defaultMultiReportRow]
        }
      };
    default:
      return state;
  }
};
